/* eslint-disable @next/next/no-img-element */
//containers + components
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import { Container } from '@/components/Grid';
import { Title1 } from '@/components/Text';

// context containers
import { Card } from './containers/';

//styles
import { PageWrapper, ProductsFeatured, TitleSection } from './Login.styles';

//logo
import ShipperGuideLogo from '@/public/images/logos/logo_shipperguide.svg';
import OpendockLogo from '@/public/images/logos/logo_opendock.svg';
import CarrierTMSLogo from '@/public/images/logos/logo_carriertms.svg';
import NavtracLogo from '@/public/images/logos/logo_navtrac.svg';

const cards = [
  {
    title: 'SHIPPER GUIDE',
    logo: <ShipperGuideLogo width="225.17" height="30" />,
    description:
      'Plan, procure, and execute freight with the nextgen Transportation Management System. Manage rates with any provider of choice, easily tender shipments, and share real-time tracking with customers.',
    tag: 'shipper',
    img: {
      src: '/images/login/shipperguide_dash.jpg',
      alt: 'ShipperGuide Dashboard',
    },
    login: {
      url: 'https://accounts.loadsmart.com/auth/login/',
      label: 'Login',
    },
    signup: {
      url: 'https://app.shipper.guide/signup',
      label: 'Signup',
    },
  },
  {
    title: 'CARRIER TMS',
    logo: <CarrierTMSLogo width="208.19" height="30" />,
    description:
      'Make managing your day-to-day operations easier with CarrierTMS, a truck management system for carriers that provides data insights and visibility into your operations, fills your truck at better rates, reduces back office expenses, and drives profitability.',
    tag: 'carrier',
    img: {
      src: '/images/login/carrier_dash.jpg',
      alt: 'Carrier Dashboard',
    },
    login: {
      url: 'https://app.carriertms.io/login',
      label: 'Login',
    },
    signup: {
      url: 'https://app.carriertms.io/login?state=checkAccount',
      label: 'Signup',
    },
  },
  {
    title: 'OPENDOCK LOADBOARD',
    logo: <OpendockLogo width="196" height="30" />,
    description:
      'Use our easy-to-use dock scheduling software that gives warehouses full visibility into their schedules and streamlines carrier appointment booking. Get built-in reporting, carrier scorecarding, and track performance metrics such as on-time rates, cancellations, and average lead times - all in one place.',
    tag: 'warehouse',
    img: {
      src: '/images/login/opendock_dash_02.jpg',
      alt: 'Opendock Dashboard',
    },
    login: {
      url: 'https://nova.opendock.com/login',
      label: 'Login',
    },
    signup: {
      url: 'https://opendock.com/#request-a-demo',
      label: 'Request a Demo',
    },
  },
  {
    title: 'CARRIER LOADBOARD',
    logo: null,
    description:
      'Find the right freight to keep your trucks full with our loadboard. Gain access to freight technology, fleet management services, and instantly bookable shipments directly through our connectivity to reliable shipper loads across the industry. Quickly sort through thousands of available loads by pickup location, destination, rate, and other factors.',
    tag: 'carrier',
    img: {
      src: '/images/login/alice_dash.jpg',
      alt: 'Carrier Loadboard',
    },
    login: {
      url: 'https://carrier.loadsmart.com/#/login?href=%2Floads',
      label: 'Login',
    },
    signup: {
      url: 'https://carrier.loadsmart.com/#/signup',
      label: 'Signup',
    },
  },
  {
    title: 'CARRIER OPENDOCK',
    logo: null,
    description:
      'Take control of your calendar by booking appointments directly with your warehouse partners. Real-time visibility into appointment and shipment details allows for fewer delays, fewer detention fees, and more time on the road, turning miles into money.',
    tag: 'carrier',
    img: {
      src: '/images/login/opendock_dash_01.jpg',
      alt: 'Carrier Loadboard',
    },
    login: {
      url: 'https://schedule.opendock.com/login',
      label: 'Login',
    },
    signup: {
      url: 'https://schedule.opendock.com/register',
      label: 'Signup',
    },
  },
  {
    title: 'GATE AND YARD VISIBILITY',
    logo: <NavtracLogo width="194" height="34" />,
    description:
      'Navtrac is an AI-Powered gate management system that uses advanced optical character recognition (OCR) and computer vision technology to detect and record all assets entering and leaving your yard.',
    tag: 'warehouse',
    img: {
      src: '/images/login/navtrac_dash.jpg',
      alt: 'Navtrac Loadboard',
    },
    login: {
      url: 'https://yms.navtrac.com/auth',
      label: 'Login',
    },
    signup: {
      url: 'https://www.navtrac.com/#wf-form-Get-In-Touch-Form',
      label: 'Request a Demo',
    },
  },
];

const Login = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Container>
          <TitleSection>
            <Title1>
              Everything you need to move more with less, in one place
            </Title1>
          </TitleSection>
          <ProductsFeatured>
            {cards &&
              cards.map((item, key) => {
                return <Card key={key + '-login-item'} content={item} />;
              })}
          </ProductsFeatured>
        </Container>
      </>
      <Footer />
    </PageWrapper>
  );
};

export default Login;
export { Login };
